<template>
  <f7-page>
    <f7-navbar title="餐盒订单" back-link="Back"></f7-navbar>
    <f7-list media-list>
      <f7-list-item
        title="肉菜类"
        subtitle="申请时间：2019-01-03"
      >
        <div slot="after">运送中</div>
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
export default {}
</script>