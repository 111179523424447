<template>
  <f7-page @page:reinit="reinit()" style="background-color: #fff;">
    <f7-navbar>
      <f7-nav-left>
        <f7-link icon-ios="f7:menu" icon-md="material:menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title>餐盒管理</f7-nav-title>
      <f7-nav-right>
        <f7-link href="/ecobox-buy/" style="font-size: 17px;">加购</f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div class="balance-wrapper bg-color-green display-flex justify-content-space-around align-items-center" style="flex-direction: column;">
      <div class="balance">
        <div class="description">
          已使用数量
        </div>
        <div class="amount">
          {{usage.history_used}}
        </div>
        <div class="description small">
          * 统计餐厅所有订单使用过的餐盒数量
        </div>
      </div>
    </div>
    <f7-block-title>餐盒余量</f7-block-title>
    <f7-list>
      <f7-list-item title="大圆" :after="usage.balance_a"></f7-list-item>
      <f7-list-item title="小圆" :after="usage.balance_b"></f7-list-item>
      <f7-list-item title="方盒" :after="usage.balance_c"></f7-list-item>
    </f7-list>
    <f7-block-title>餐盒订单</f7-block-title>
    <f7-block v-if="orders.length === 0">
      <p>暂无餐盒订单</p>
    </f7-block>
    <f7-list v-else>
      <f7-list-item
        :title="order.created_time | moment('MM月DD日')"
        :after="formatState(order.state)"
        v-for="order in orders"
        :key="order.id"
        popup-open=".container-order-detail"
        @click="fetchOrderDetail(order.id)"
        link="#"
      >
      </f7-list-item>
    </f7-list>
    <f7-popup class="container-order-detail">
      <f7-page v-if="container_order_detail">
        <f7-navbar title="餐盒订单详情">
          <f7-nav-right>
            <f7-link popup-close style="font-size: 17px;">关闭</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block-title>订单状态</f7-block-title>
        <f7-list>
          <f7-list-item title="创建时间" :after="container_order_detail.created_time | moment('YYYY年MM月DD日')"></f7-list-item>
          <f7-list-item title="订单状态" :after="formatState(container_order_detail.state)"></f7-list-item>
        </f7-list>
        <f7-block-title>采购项目</f7-block-title>
        <f7-list>
          <f7-list-item title="大圆" :after="container_order_detail.amount_a.toString()"></f7-list-item>
          <f7-list-item title="小圆" :after="container_order_detail.amount_b.toString()"></f7-list-item>
          <f7-list-item title="方盒" :after="container_order_detail.amount_c.toString()"></f7-list-item>
        </f7-list>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>

<script>
import invoke from '../libs/invoke'

export default {
  data() {
    return {
      usage: {},
      orders: [],
      container_order_detail: null
    }
  },
  async mounted() {
    await this.fetchData()
  },
  methods: {
    formatState(state) {
      return ({
        'REQUESTED': '已申请',
        'ACCEPTED': '备货中',
        'RESOLVED': '已配送',
        'REJECTED': '已拒绝'
      })[state]
    },
    async reinit() {
      await this.fetchData()
    },
    async fetchData() {
      this.usage = await invoke('container.getUsage')
      this.orders = await invoke('container.getOrders')
    },
    async fetchOrderDetail(container_order_id) {
      this.container_order_detail = null
      this.container_order_detail = await invoke('container.getOrder', { container_order_id })
    }
  }
}
</script>

<style scoped>
.balance-wrapper {
  height: 250px;
  background-color: var(--f7-theme-color-bg-color);
}
.balance-wrapper>.balance {
  text-align: center;
}
.balance-wrapper>.balance>.description {
  color: #fff;
}
.balance-wrapper>.balance>.amount {
  font-size: 56px;
  color: #fff;
}
.balance-wrapper>.balance>.description.small {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.8)
}
</style>