import _ from 'lodash'
import moment from 'moment'

const unprocessedOrders = [{
  sn: '001',
  createdTime: moment('2019-09-19T15:20:00Z').unix(),
  exceptTime: moment('2019-09-19T16:30:00Z').unix(),
  state: 'unprocessed',
  timeline: [],
  customer: {
    name: '王先生',
    phone: '138123411234'
  },
  memo: '多加些辣椒哦',
  invoce: '上海鼎栈信息科技有限公司',
  items: [{
    index: 0,
    name: '红烧肉',
    count: 1,
    price: 38,
    attrs: ['少糖']
  }, {
    index: 1,
    name: '糖醋排骨',
    count: 1,
    price: 55,
    attrs: []
  }, {
    index: 2,
    name: '红烧排骨',
    count: 1,
    price: 45,
    attrs: []
  }, {
    index: 3,
    name: '可乐鸡翅',
    count: 1,
    price: 34,
    attrs: []
  }, {
    index: 4,
    name: '鱼香肉丝',
    count: 1,
    price: 23,
    attrs: ['加鱼肉']
  }, {
    index: 5,
    name: '米饭',
    count: 3,
    price: 6,
    attrs: []
  }],
  limitoff: 20,
  coupon: 5,
  total: 176
}, {
  sn: '002',
  createdTime: moment('2019-09-19T15:20:00Z').unix(),
  exceptTime: moment('2019-09-19T16:30:00Z').unix(),
  state: 'unprocessed',
  timeline: [],
  customer: {
    name: '李小姐',
    phone: '138123411234'
  },
  memo: '不要辣椒哦',
  invoce: '上海鼎栈信息科技有限公司',
  items: [{
    index: 0,
    name: '奶茶',
    count: 1,
    price: 38,
    attrs: ['少糖']
  }, {
    index: 1,
    name: '糖醋排骨',
    count: 1,
    price: 55,
    attrs: []
  }, {
    index: 2,
    name: '红烧排骨',
    count: 1,
    price: 45,
    attrs: []
  }, {
    index: 3,
    name: '可乐鸡翅',
    count: 1,
    price: 34,
    attrs: []
  }],
  limitoff: 20,
  coupon: 5,
  total: 176
}, {
  sn: '003',
  createdTime: moment('2019-09-19T15:20:00Z').unix(),
  exceptTime: moment('2019-09-19T16:30:00Z').unix(),
  state: 'unprocessed',
  timeline: [],
  customer: {
    name: '李建刚',
    phone: '138123411234'
  },
  memo: '多加些辣椒哦',
  invoce: '上海鼎栈信息科技有限公司',
  items: [{
    index: 0,
    name: '红烧肉',
    count: 1,
    price: 38,
    attrs: ['少糖']
  }, {
    index: 1,
    name: '糖醋排骨',
    count: 1,
    price: 55,
    attrs: []
  }, {
    index: 2,
    name: '红烧排骨',
    count: 1,
    price: 45,
    attrs: []
  }, {
    index: 3,
    name: '可乐鸡翅',
    count: 1,
    price: 34,
    attrs: []
  }, {
    index: 4,
    name: '鱼香肉丝',
    count: 1,
    price: 23,
    attrs: ['加鱼肉']
  }, {
    index: 5,
    name: '米饭',
    count: 3,
    price: 6,
    attrs: []
  }],
  limitoff: 20,
  coupon: 5,
  total: 176
}]

const processedOrders = [{
  sn: '011',
  createdTime: moment('2019-09-19T15:20:00Z').unix(),
  exceptTime: moment('2019-09-19T16:30:00Z').unix(),
  state: 'processed',
  timeline: [{
    index: 0,
    from: 'unprocessed',
    to: 'processed',
    time: moment('2019-09-19T15:25:00Z').unix(),
    action: 'MERCHANT_ACCEPT',
    description: '商家接单'
  }],
  customer: {
    name: '小明',
    phone: '138123411234'
  },
  memo: '多加些辣椒哦',
  invoce: '上海鼎栈信息科技有限公司',
  items: [{
    index: 0,
    name: '红烧肉',
    count: 1,
    price: 38,
    attrs: ['少糖']
  }, {
    index: 1,
    name: '糖醋排骨',
    count: 1,
    price: 55,
    attrs: []
  }, {
    index: 2,
    name: '红烧排骨',
    count: 1,
    price: 45,
    attrs: []
  }, {
    index: 3,
    name: '可乐鸡翅',
    count: 1,
    price: 34,
    attrs: []
  }, {
    index: 4,
    name: '鱼香肉丝',
    count: 1,
    price: 23,
    attrs: ['加鱼肉']
  }, {
    index: 5,
    name: '米饭',
    count: 3,
    price: 6,
    attrs: []
  }],
  limitoff: 20,
  coupon: 5,
  total: 176
}, {
  sn: '012',
  createdTime: moment('2019-09-19T15:20:00Z').unix(),
  exceptTime: moment('2019-09-19T16:30:00Z').unix(),
  state: 'processed',
  timeline: [{
    index: 0,
    from: 'unprocessed',
    to: 'processed',
    time: moment('2019-09-19T15:25:00Z').unix(),
    action: 'MERCHANT_ACCEPT',
    description: '商家接单'
  }],
  customer: {
    name: '李艳红',
    phone: '138123411234'
  },
  memo: '不要辣椒哦',
  invoce: '上海鼎栈信息科技有限公司',
  items: [{
    index: 0,
    name: '奶茶',
    count: 1,
    price: 38,
    attrs: ['少糖']
  }, {
    index: 1,
    name: '糖醋排骨',
    count: 1,
    price: 55,
    attrs: []
  }, {
    index: 2,
    name: '红烧排骨',
    count: 1,
    price: 45,
    attrs: []
  }, {
    index: 3,
    name: '可乐鸡翅',
    count: 1,
    price: 34,
    attrs: []
  }],
  limitoff: 20,
  coupon: 5,
  total: 176
}]

const waitingOrders = [{
  sn: '021',
  createdTime: moment('2019-09-19T15:20:00Z').unix(),
  exceptTime: moment('2019-09-19T16:30:00Z').unix(),
  state: 'waiting',
  timeline: [{
    index: 0,
    from: 'unprocessed',
    to: 'processed',
    time: moment('2019-09-19T15:25:00Z').unix(),
    action: 'MERCHANT_ACCEPT',
    description: '商家接单'
  }, {
    index: 1,
    from: 'processed',
    to: 'waiting',
    time: moment('2019-09-19T15:35:00Z').unix(),
    action: 'MERCHANT_FINSHED',
    description: '菜品制作完成'
  }],
  customer: {
    name: '小明',
    phone: '138123411234'
  },
  memo: '多加些辣椒哦',
  invoce: '上海鼎栈信息科技有限公司',
  items: [{
    index: 0,
    name: '红烧肉',
    count: 1,
    price: 38,
    attrs: ['少糖']
  }, {
    index: 1,
    name: '糖醋排骨',
    count: 1,
    price: 55,
    attrs: []
  }, {
    index: 2,
    name: '红烧排骨',
    count: 1,
    price: 45,
    attrs: []
  }, {
    index: 3,
    name: '可乐鸡翅',
    count: 1,
    price: 34,
    attrs: []
  }, {
    index: 4,
    name: '鱼香肉丝',
    count: 1,
    price: 23,
    attrs: ['加鱼肉']
  }, {
    index: 5,
    name: '米饭',
    count: 3,
    price: 6,
    attrs: []
  }],
  limitoff: 20,
  coupon: 5,
  total: 176
}, {
  sn: '022',
  createdTime: moment('2019-09-19T15:20:00Z').unix(),
  exceptTime: moment('2019-09-19T16:30:00Z').unix(),
  state: 'waiting',
  timeline: [{
    index: 0,
    from: 'unprocessed',
    to: 'processed',
    time: moment('2019-09-19T15:25:00Z').unix(),
    action: 'MERCHANT_ACCEPT',
    description: '商家接单'
  }, {
    index: 1,
    from: 'processed',
    to: 'waiting',
    time: moment('2019-09-19T15:35:00Z').unix(),
    action: 'MERCHANT_FINSHED',
    description: '菜品制作完成'
  }],
  customer: {
    name: '李艳红',
    phone: '138123411234'
  },
  memo: '不要辣椒哦',
  invoce: '上海鼎栈信息科技有限公司',
  items: [{
    index: 0,
    name: '奶茶',
    count: 1,
    price: 38,
    attrs: ['少糖']
  }, {
    index: 1,
    name: '糖醋排骨',
    count: 1,
    price: 55,
    attrs: []
  }, {
    index: 2,
    name: '红烧排骨',
    count: 1,
    price: 45,
    attrs: []
  }, {
    index: 3,
    name: '可乐鸡翅',
    count: 1,
    price: 34,
    attrs: []
  }],
  limitoff: 20,
  coupon: 5,
  total: 176
}]

const cancelingOrders = [{
  sn: '031',
  createdTime: moment('2019-09-19T15:20:00Z').unix(),
  exceptTime: moment('2019-09-19T16:30:00Z').unix(),
  state: 'canceling',
  timeline: [{
    index: 0,
    from: 'unprocessed',
    to: 'processed',
    time: moment('2019-09-19T15:25:00Z').unix(),
    action: 'MERCHANT_ACCEPT',
    description: '商家接单'
  }, {
    index: 1,
    from: 'waiting',
    to: 'canceling',
    time: moment('2019-09-19T15:45:00Z').unix(),
    action: 'USER_CANCEL_REQUEST',
    description: '点错商品'
  }],
  customer: {
    name: '小明',
    phone: '138123411234'
  },
  memo: '多加些辣椒哦',
  invoce: '上海鼎栈信息科技有限公司',
  items: [{
    index: 0,
    name: '红烧肉',
    count: 1,
    price: 38,
    attrs: ['少糖']
  }, {
    index: 1,
    name: '糖醋排骨',
    count: 1,
    price: 55,
    attrs: []
  }, {
    index: 2,
    name: '红烧排骨',
    count: 1,
    price: 45,
    attrs: []
  }, {
    index: 3,
    name: '可乐鸡翅',
    count: 1,
    price: 34,
    attrs: []
  }, {
    index: 4,
    name: '鱼香肉丝',
    count: 1,
    price: 23,
    attrs: ['加鱼肉']
  }, {
    index: 5,
    name: '米饭',
    count: 3,
    price: 6,
    attrs: []
  }],
  limitoff: 20,
  coupon: 5,
  total: 176
}, {
  sn: '032',
  createdTime: moment('2019-09-19T15:20:00Z').unix(),
  exceptTime: moment('2019-09-19T16:30:00Z').unix(),
  state: 'canceling',
  timeline: [{
    index: 0,
    from: 'unprocessed',
    to: 'processed',
    time: moment('2019-09-19T15:25:00Z').unix(),
    action: 'MERCHANT_ACCEPT',
    description: '商家接单'
  }, {
    index: 1,
    from: 'waiting',
    to: 'canceling',
    time: moment('2019-09-19T15:45:00Z').unix(),
    action: 'USER_CANCEL_REQUEST',
    description: '重复订单'
  }],
  customer: {
    name: '李艳红',
    phone: '138123411234'
  },
  memo: '不要辣椒哦',
  invoce: '上海鼎栈信息科技有限公司',
  items: [{
    index: 0,
    name: '奶茶',
    count: 1,
    price: 38,
    attrs: ['少糖']
  }, {
    index: 1,
    name: '糖醋排骨',
    count: 1,
    price: 55,
    attrs: []
  }, {
    index: 2,
    name: '红烧排骨',
    count: 1,
    price: 45,
    attrs: []
  }, {
    index: 3,
    name: '可乐鸡翅',
    count: 1,
    price: 34,
    attrs: []
  }],
  limitoff: 20,
  coupon: 5,
  total: 176
}]

const historyOrders = [{
  sn: '041',
  createdTime: moment('2019-09-19T15:20:00Z').unix(),
  exceptTime: moment('2019-09-19T16:30:00Z').unix(),
  state: 'closed',
  timeline: [{
    index: 0,
    from: 'unprocessed',
    to: 'processed',
    time: moment('2019-09-19T15:25:00Z').unix(),
    action: 'MERCHANT_ACCEPT',
    description: '商家接单'
  }, {
    index: 1,
    from: 'processed',
    to: 'waiting',
    time: moment('2019-09-19T15:35:00Z').unix(),
    action: 'MERCHANT_FINSHED',
    description: '菜品制作完成'
  }, {
    index: 2,
    from: 'waiting',
    to: 'closed',
    time: moment('2019-09-19T15:46:00Z').unix(),
    action: 'USER_PICKUP',
    description: '用户已取餐'
  }],
  customer: {
    name: '小明',
    phone: '138123411234'
  },
  memo: '多加些辣椒哦',
  invoce: '上海鼎栈信息科技有限公司',
  items: [{
    index: 0,
    name: '红烧肉',
    count: 1,
    price: 38,
    attrs: ['少糖']
  }, {
    index: 1,
    name: '糖醋排骨',
    count: 1,
    price: 55,
    attrs: []
  }, {
    index: 2,
    name: '红烧排骨',
    count: 1,
    price: 45,
    attrs: []
  }, {
    index: 3,
    name: '可乐鸡翅',
    count: 1,
    price: 34,
    attrs: []
  }, {
    index: 4,
    name: '鱼香肉丝',
    count: 1,
    price: 23,
    attrs: ['加鱼肉']
  }, {
    index: 5,
    name: '米饭',
    count: 3,
    price: 6,
    attrs: []
  }],
  limitoff: 20,
  coupon: 5,
  total: 176
}, {
  sn: '042',
  createdTime: moment('2019-09-19T15:20:00Z').unix(),
  exceptTime: moment('2019-09-19T16:30:00Z').unix(),
  state: 'closed',
  timeline: [{
    index: 0,
    from: 'unprocessed',
    to: 'processed',
    time: moment('2019-09-19T15:25:00Z').unix(),
    action: 'MERCHANT_ACCEPT',
    description: '商家接单'
  }, {
    index: 1,
    from: 'processed',
    to: 'waiting',
    time: moment('2019-09-19T15:35:00Z').unix(),
    action: 'MERCHANT_FINSHED',
    description: '菜品制作完成'
  }, {
    index: 2,
    from: 'waiting',
    to: 'closed',
    time: moment('2019-09-19T15:46:00Z').unix(),
    action: 'USER_PICKUP',
    description: '用户已取餐'
  }],
  customer: {
    name: '李艳红',
    phone: '138123411234'
  },
  memo: '不要辣椒哦',
  invoce: '上海鼎栈信息科技有限公司',
  items: [{
    index: 0,
    name: '奶茶',
    count: 1,
    price: 38,
    attrs: ['少糖']
  }, {
    index: 1,
    name: '糖醋排骨',
    count: 1,
    price: 55,
    attrs: []
  }, {
    index: 2,
    name: '红烧排骨',
    count: 1,
    price: 45,
    attrs: []
  }, {
    index: 3,
    name: '可乐鸡翅',
    count: 1,
    price: 34,
    attrs: []
  }],
  limitoff: 20,
  coupon: 5,
  total: 176
}]

export let data = {
  unprocessedOrders: unprocessedOrders,
  processedOrders: processedOrders,
  waitingOrders: waitingOrders,
  cancelingOrders: cancelingOrders,
  historyOrders: historyOrders
}
