<template>
  <!-- Login Screen -->
  <f7-login-screen :opened="is_login_show">
    <f7-view url="/login/">
      <f7-page login-screen>
        <f7-login-screen-title>Sesame Eats</f7-login-screen-title>
        <f7-list form>
          <f7-list-input
            label="用户名"
            type="text"
            placeholder="请输入用户名"
            :value="username"
            @input="username = $event.target.value"
            required
            validate
          ></f7-list-input>
          <f7-list-input
            label="密码"
            type="password"
            placeholder="请输入密码"
            :value="password"
            @input="password = $event.target.value"
            required
            validate
          ></f7-list-input>
        </f7-list>
        <f7-list>
          <f7-list-button @click="signIn" fill>登陆</f7-list-button>
          <f7-block-footer>许可协议</f7-block-footer>
        </f7-list>
      </f7-page>
    </f7-view>
  </f7-login-screen>
</template>
<script>
const config = require('../config/' + process.env.NODE_ENV + '.js')

export default {
  data() {
    return {
      is_login_show: true,
      username: '',
      password: ''
    }
  },
  mounted() {
    const token = localStorage.getItem('token')
  },
  methods: {
    signIn() {
      return fetch(`${config.server}/apiv1/merchant/auth`, {
        method: 'POST',
        body: JSON.stringify({
          username: this.username,
          password: this.password
        }),
        headers: { 'Content-Type': 'application/json' }
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            this.$f7.dialog.alert(res.error.message, '登陆失败')
          } else {
            localStorage.setItem('token', res.token)
            this.is_login_show = false
            this.$f7router.navigate(`/`, {
              reloadAll: true
            })
          }
        })
    }
  }
}
</script>
<style scoped>

</style>
