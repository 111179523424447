<template>
  <f7-page style="background-color: #fff">
    <f7-navbar :title="action === 'create' ? '菜品创建' : '菜品编辑'" back-link="Back">
      <f7-nav-right>
        <f7-link @click="$f7router.navigate(`/specs/`, { props: { dish_id: dish.id } })" text="加料" style="font-size: 17px;" v-if="action !== 'create'"></f7-link>
        <f7-link @click="save()" text="保存" style="font-size: 17px;"></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div class="dish-image">
      <div class="placeholder display-flex justify-content-center align-items-center" :style="{opacity: (dish.imgurl ? 0 : 1)}">
        <f7-icon material="photo_camera" size="48" color="gray"></f7-icon>
        <form enctype="multipart/form-data" novalidate>
          <input type="file" name="file" :disabled="isSaving" @change="fileChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
            accept="image/*" class="input-file">
        </form>
      </div>
      <img :src="`${dish.imgurl}?x-oss-process=style/dish_img`" v-if="dish.imgurl">
    </div>
    <f7-block-title>属性</f7-block-title>
    <f7-list no-hairlines-md>
      <f7-list-input
        label="名称"
        type="text"
        placeholder="菜品名称"
        @input="dish.name = $event.target.value"
        :value="dish.name"
      ></f7-list-input>

      <f7-list-input
        label="原价"
        type="number"
        placeholder="菜品原价"
        @input="dish.original_price = ($event.target.value / 1)"
        :value="dish.original_price"
      ></f7-list-input>

      <f7-list-input
        label="现价（若无优惠，请与原价保持一致）"
        type="number"
        placeholder="菜品现价"
        @input="dish.current_price = ($event.target.value / 1)"
        :value="dish.current_price"
      ></f7-list-input>

      <f7-list-input
        label="每日库存"
        type="number"
        placeholder="菜品每日库存数量"
        @input="dish.count = ~~$event.target.value"
        :value="dish.count"
      ></f7-list-input>

      <f7-list-input
        label="剩余库存"
        type="number"
        placeholder="菜品剩余库存数量"
        @input="dish.count_left = ~~$event.target.value"
        :value="dish.count_left"
        v-if="this.action === 'edit'"
      ></f7-list-input>

      <f7-list-input
        label="原料"
        type="text"
        placeholder="菜品短描述"
        @input="dish.short_desc = $event.target.value"
        :value="dish.short_desc"
      ></f7-list-input>

      <f7-list-input
        label="描述"
        type="textarea"
        placeholder="菜品长描述"
        @input="dish.long_desc = $event.target.value"
        :value="dish.long_desc"
      ></f7-list-input>
    </f7-list>
    <f7-block-title>菜品分类</f7-block-title>
    <f7-list v-if="categorys.length > 0" no-hairlines-md>
      <f7-list-item title="分类" smart-select :smart-select-params="{ openIn: 'sheet', closeOnSelect: true }">
        <select name="categorys" :value="dish.category_id" v-model="dish.category_id">
          <option :value="cat.id" :selected="dish.category_id === cat.id" v-for="cat in categorys" :key="cat.id">{{cat.name}}</option>
        </select>
      </f7-list-item>
    </f7-list>
    <f7-block-title>餐盒需求</f7-block-title>
    <f7-list no-hairlines-md>
      <f7-list-item title="大圆" v-if="dish.boxa_num !== null || action !== 'edit'">
        <f7-stepper
          slot="after"
          :value="dish.boxa_num"
          @input="dish.boxa_num = ~~$event.target.value"
          :step="1"
          :max="10"
          small
          raised
        ></f7-stepper>
      </f7-list-item>
      <f7-list-item title="小圆" v-if="dish.boxb_num !== null || action !== 'edit'">
        <f7-stepper
          slot="after"
          :value="dish.boxb_num"
          @input="dish.boxb_num = ~~$event.target.value"
          :step="1"
          :max="10"
          small
          raised
        ></f7-stepper>
      </f7-list-item>
      <f7-list-item title="方盒" v-if="dish.boxc_num !== null || action !== 'edit'">
        <f7-stepper
          slot="after"
          :value="dish.boxc_num"
          @input="dish.boxc_num = ~~$event.target.value"
          :step="1"
          :max="10"
          small
          raised
        ></f7-stepper>
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import invoke from '../libs/invoke'
import upload from '../libs/upload'

export default {
  props: ['action', 'dish_id'],
  data() {
    return {
      dish: {
        boxa_num: this.action === 'edit' ? null : 0,
        boxb_num: this.action === 'edit' ? null : 0,
        boxc_num: this.action === 'edit' ? null : 0
      },
      categorys: [],
      isSaving: false
    }
  },
  methods: {
    log(v) {
      console.log(v)
    },
    check() {
      if (!this.dish.imgurl) {
        this.$f7.dialog.alert('还没有上传图片', '提示')
        return false
      }
      if (!this.dish.name) {
        this.$f7.dialog.alert('还没有填写菜品名称', '提示')
        return false
      }
      if (!this.dish.short_desc) {
        this.$f7.dialog.alert('还没有填写菜品原料', '提示')
        return false
      }
      if (!this.dish.long_desc) {
        this.$f7.dialog.alert('还没有填写菜品描述', '提示')
        return false
      }
      if (!this.dish.category_id) {
        this.$f7.dialog.alert('还没有选择菜品分类', '提示')
        return false
      }
      if (this.dish.current_price > this.dish.original_price) {
        this.$f7.dialog.alert('菜品现价不能大于原价', '提示')
        return false
      }
      return true
    },
    async save() {
      if (!this.check()) { return }
      if (this.action === 'edit') {
        await invoke('dish.updateDish', Object.assign({}, this.dish, { dish_id: this.dish.id }))
      } else {
        await invoke('dish.createDish', Object.assign({}, {
          boxa_num: 0,
          boxb_num: 0,
          boxc_num: 0,
          count: 0,
          count_left: 0,
          original_price: 0,
          current_price: 0,
          short_desc: '',
          long_desc: ''
        }, this.dish))
      }
      this.$f7router.back()
    },
    async upload(formData) {
      // upload data to the server
      this.isSaving = true
      const result = await upload(formData)
      this.dish.imgurl = result.url
      this.isSaving = false
    },
    fileChange(fieldName, fileList) {
      // handle file changes
      const formData = new FormData();

      if (!fileList.length) return;

      // append the files to FormData
      Array
        .from(Array(fileList.length).keys())
        .map(x => {
          formData.append(fieldName, fileList[x], fileList[x].name);
        });

      // save it
      this.upload(formData);
    }
  },
  async mounted() {
    if (this.action === 'edit') {
      let dish = dish = await invoke('dish.getDish', { dish_id: this.dish_id })
      this.dish = Object.assign({}, dish, {
        current_price: dish.current_price / 1,
        original_price: dish.original_price / 1
      })
    }
    this.categorys = await invoke('dish.getCategorys')
  }
}
</script>

<style scoped>
.placeholder {
  background-color: #eee;
  position: absolute;
  top: 0;
  left: 0;
  --div-width: calc(100vw);
	width: var(--div-width);
	height: var(--div-width);
}
.dish-image {
  position: relative;
}
.dish-image, .dish-image img {
  --div-width: calc(100vw);
	width: var(--div-width);
	height: var(--div-width);
  display: block;
}
.input-file {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  --div-width: calc(100vw);
	width: var(--div-width);
	height: var(--div-width);
  position: absolute;
}
</style>