<template>
  <!-- App -->
  <f7-app :params="f7params">

    <!-- Statusbar -->
    <f7-statusbar></f7-statusbar>

    <!-- Left Panel -->
    <f7-panel left reveal theme-dark>
      <f7-view url="/panel-left/"></f7-view>
    </f7-panel>

    <!-- Main View -->
    <f7-view id="main-view" main class="safe-areas"></f7-view>
  </f7-app>
</template>

<script>
// Import Routes
import routes from './routes.js'

export default {
  data() {
    return {
      loginScreenOpened: true,
      username: '',
      password: '',
      // Framework7 parameters here
      f7params: {
        id: 'com.deansel.ecobox', // App bundle ID
        name: 'Ecobox', // App name
        theme: 'md', // Automatic theme detection
        // App routes
        routes: routes,
      },
    }
  },
  mounted() {
    global.$f7 = this.$f7
    global.$eco = {}
    global.$eco.error = (err) => {
      const message = this.$f7.notification.create({
        title: 'Sesame Eats',
        titleRightText: '刚刚',
        text: err.message,
        closeTimeout: 3000,
      })
      message.open()
    }
  }
}
</script>

<style>
:root {
  --f7-font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  --f7-page-bg-color: #efeff4;
  --f7-card-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  --f7-searchbar-input-bg-color: var(--f7-navbar-bg-color, var(--f7-bars-bg-color, var(--f7-theme-color)));

  --f7-notification-margin: 10px;
  --f7-notification-padding: 10px;
  --f7-notification-border-radius: 4px;
  --f7-notification-box-shadow: 0px 5px 25px -10px rgba(0, 0, 0, 0.7);
  --f7-notification-icon-size: 18px;
  --f7-notification-title-font-size: 13px;
  --f7-notification-title-text-transform: uppercase;
  --f7-notification-title-line-height: 1.4;
  --f7-notification-title-font-weight: 500;
  --f7-notification-title-letter-spacing: 0.02em;
  --f7-notification-title-right-font-size: 13px;
  --f7-notification-subtitle-font-size: 12px;
  --f7-notification-subtitle-font-weight: 600;
  --f7-notification-text-font-size: 12px;
  --f7-notification-text-line-height: 1.2;
  --f7-notification-bg-color: #fff;
  --f7-notification-title-color: #000;
  --f7-notification-title-right-color: rgba(255, 255, 255, 0.6);
  --f7-notification-subtitle-color: #000;
  --f7-notification-text-color: #000;
}
</style>