<template>
  <f7-page>
    <!-- <f7-navbar title="Left Panel"></f7-navbar> -->
    <!-- <f7-block-title>Load page in panel</f7-block-title> -->
    <div class="logo">
      <img src="/static/logo_en.png" alt="">
    </div>
    <f7-list>
      <f7-list-item link="/" title="订单处理" view="#main-view" :panel-close="true" :reload-current="true"></f7-list-item>
      <f7-list-item link="/order-history/" title="历史订单" view="#main-view" :panel-close="true" :reload-current="true"></f7-list-item>
      <f7-list-item link="/profile/" title="餐厅" view="#main-view" :panel-close="true" :reload-current="true"></f7-list-item>
      <f7-list-item link="/menu/" title="菜单" view="#main-view" :panel-close="true" :reload-current="true"></f7-list-item>
      <f7-list-item link="/coupon/" title="优惠" view="#main-view" :panel-close="true" :reload-current="true"></f7-list-item>
      <f7-list-item link="/ecobox/" title="餐盒" view="#main-view" :panel-close="true" :reload-current="true"></f7-list-item>
      <f7-list-item link="/account/" title="提款" view="#main-view" :panel-close="true" :reload-current="true"></f7-list-item>
      <f7-list-item link="/about/" title="帮助" view="#main-view" :panel-close="true" :reload-current="true"></f7-list-item>
    </f7-list>
  </f7-page>
</template>
<script>
export default {}
</script>
<style scoped>
.logo img {
  display: block;
  width: 100%;
}
.list {
  margin: 0;
}
</style>