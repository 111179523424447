<template>
  <f7-page>
    <f7-navbar title="分类管理" back-link="Back">
      <f7-nav-right>
        <f7-link icon-md="material:sort" sortable-toggle=".category-list"></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-list media-list sortable @sortable:sort="onSort" class="category-list">
      <f7-list-item
        swipeout
        @swipeout:deleted="onRemoveCategory(category)"
        :title="category.name"
        link="#"
        v-for="category in categorys"
        :key="category.id"
        @click="updateCategory(category)"
      >
        <f7-swipeout-actions right>
          <f7-swipeout-button delete confirm-title="确认操作" confirm-text="你确定要删除该分类吗">删除</f7-swipeout-button>
        </f7-swipeout-actions>
      </f7-list-item>
    </f7-list>
    <f7-fab position="right-bottom" slot="fixed" color="red" @click="createCategory()">
      <f7-icon ios="f7:add" aurora="f7:add" md="material:add"></f7-icon>
    </f7-fab>
  </f7-page>
</template>

<script>
import invoke from '../libs/invoke'
import _ from 'lodash'

export default {
  data() {
    return {
      categorys: []
    }
  },
  methods: {
    move(array,fromIndex,toIndex) {
      array.splice(toIndex, 0, array.splice(fromIndex, 1)[0] );
    },
    async fetchData() {
      this.categorys = await invoke('dish.getCategorys')
    },
    async createCategory() {
      this.$f7.dialog.prompt('请输入分类名称：', '添加分类', async (name) => {
        await invoke('dish.createCategory', { name })
        await this.fetchData()
      })
    },
    async updateCategory(category) {
      this.$f7.dialog.prompt('请输入新分类名称：', '编辑分类', async (name) => {
        await invoke('dish.updateCategory', { category_id: category.id, name })
        await this.fetchData()
      }, () => {}, category.name)
    },
    async onRemoveCategory(category) {
      this.categorys = this.categorys.filter((v) => v.id !== category.id)
      try {
        await invoke('dish.removeCategory', { category_id: category.id })
        this.$f7.dialog.alert('该分类已成功删除', '操作成功')
      } catch (err) {
        this.$f7.dialog.alert(err.message, '操作失败')
      }
      await this.fetchData()
    },
    async onSort(e) {
      // Sort data
      this.move(this.categorys, e.detail.from, e.detail.to)
      const new_order = this.categorys.map((v, k) => {
        return {
          category_id: v.id,
          sort: k
        }
      })
      await invoke('dish.updateCategorySort', { new_order })
    }
  },
  async mounted() {
    await this.fetchData()
  }
}
</script>