<template>
  <f7-page :page-content="false" @page:reinit="reinit()">
    <f7-navbar>
      <f7-nav-left>
        <f7-link icon-md="material:menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title>菜品管理</f7-nav-title>
      <f7-nav-right>
        <f7-link icon-md="material:folder_open" href="/category/"></f7-link>
        <f7-link icon-md="material:sort" sortable-toggle=".dish-list"></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-toolbar tabbar scrollable position="top">
      <f7-link
        v-for="(cat, index) in categorys"
        :key="cat.id"
        :tab-link="`#tab-${cat.id}`"
        :tab-link-active="index === 0"
        >{{cat.name}}</f7-link>
    </f7-toolbar>
    <f7-tabs>
      <f7-tab
        v-for="(cat, index) in categorys"
        :key="cat.id"
        :id="`tab-${cat.id}`"
        class="page-content"
        :tab-active="index === 0"
        @tab:show="currentCategoryId = cat.id"
      >
        <f7-block-title v-if="dishes.filter((v) => v.category_id === cat.id).length > 0">点击列表查看详情</f7-block-title>
        <f7-block-title v-if="dishes.filter((v) => v.category_id === cat.id).length === 0" class="text-align-center">请为该分类添加菜品</f7-block-title>
        <f7-list media-list sortable @sortable:sort="onSort(cat.id, $event)" class="dish-list">
          <f7-list-item
            v-for="dish in dishes.filter((v) => v.category_id === cat.id)"
            :key="dish.id"
            :title="dish.name "
            link="#"
            :subtitle="`剩余: ${dish.count_left} / ${dish.count}`"
            @click="$f7router.navigate(`/dish-edit/`, { props: { action: 'edit', dish_id: dish.id } })"
            swipeout
            @swipeout:deleted="onRemoveDish(dish)"
            :text="dish.shortDesc"
            >
              <div slot="after"><s v-if="dish.original_price !== dish.current_price">{{`¥${dish.original_price}`}}</s> {{`¥${dish.current_price}`}}</div>
              <img slot="media" :src="`${dish.imgurl}?x-oss-process=style/dish_img_small`" width="80" style="border-radius: 3px;" />
              <f7-swipeout-actions right>
                <f7-swipeout-button delete confirm-title="确认操作" confirm-text="确认要删除这道菜品吗？">删除</f7-swipeout-button>
              </f7-swipeout-actions>
          </f7-list-item>
        </f7-list>
      </f7-tab>
    </f7-tabs>
    <f7-fab position="right-bottom" slot="fixed" color="red">
      <f7-icon ios="f7:add" aurora="f7:add" md="material:add"></f7-icon>
      <f7-icon ios="f7:close" aurora="f7:close" md="material:close"></f7-icon>
      <f7-fab-buttons position="top">
        <f7-fab-button label="创建菜品" @click="$f7router.navigate(`/dish-edit/`, { props: { action: 'create' } })">新</f7-fab-button>
        <f7-fab-button label="分类置满" @click="fillStockCategory()" v-if="categorys.length > 0">满</f7-fab-button>
        <f7-fab-button label="分类沽清" @click="emptyStockCategory()" v-if="categorys.length > 0">清</f7-fab-button>
      </f7-fab-buttons>
    </f7-fab>
  </f7-page>
</template>

<script>
import invoke from '../libs/invoke'

export default {
  data() {
    return {
      categorys: [],
      dishes: [],
      isBottom: true,
      currentCategoryId: null
    };
  },
  methods: {
    async fetchData() {
      const dishes = await invoke('dish.getDishes')
      const categorys = await invoke('dish.getCategorys')
      this.dishes = dishes
      this.categorys = categorys

      if (!this.currentCategoryId && this.categorys.length > 0) { this.currentCategoryId = this.categorys[0].id }
    },
    async fillStockCategory() {
      await invoke('dish.fillStockCategory', { category_id: this.currentCategoryId })
      this.$f7.dialog.alert('分类库存已全部置满', '操作成功')
      await this.fetchData()
    },
    async emptyStockCategory() {
      await invoke('dish.emptyStockCategory', { category_id: this.currentCategoryId })
      this.$f7.dialog.alert('分类库存已全部沽清', '操作成功')
      await this.fetchData()
    },
    async onRemoveDish(dish) {
      await invoke('dish.removeDish', { dish_id: dish.id })
      this.$f7.dialog.alert('菜品已删除', '操作成功')
      await this.fetchData()
    },
    async reinit() {
      this.categorys = [];
      await this.$nextTick()
      await this.fetchData()
      if (this.categorys.length > 0) { this.currentCategoryId = this.categorys[0].id }
    },
    move(array,fromIndex,toIndex) {
      return array.splice(toIndex, 0, array.splice(fromIndex, 1)[0]);
    },
    async onSort(category_id, e) {
      let dishes = this.dishes.filter((v) => v.category_id === category_id)
      this.move(dishes, e.detail.from, e.detail.to)

      // Sort data
      // this.move(this.dishes, e.detail.from, e.detail.to)
      const new_order = dishes.map((v, k) => {
        return {
          dish_id: v.id,
          ranking: k
        }
      })
      await invoke('dish.updateDishSort', { new_order })
    }
  },
  async mounted() {
    await this.fetchData()
  }
}
</script>