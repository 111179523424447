import HomePage from './pages/home.vue';
import AboutPage from './pages/about.vue';
import DashboardPage from './pages/dashboard.vue';
import OrderPage from './pages/order.vue';
import OrderHistoryPage from './pages/order-history.vue';
import EcoboxPage from './pages/ecobox.vue';
import EcoboxBuyPage from './pages/ecobox-buy.vue';
import EcoboxOrderListPage from './pages/ecobox-order-list.vue';
import CouponPage from './pages/coupon.vue';
import MenuPage from './pages/menu.vue';
import DishEditPage from './pages/dish-edit.vue';
import SpecsPage from './pages/specs.vue';
import AccountPage from './pages/account.vue';
import CategoryPage  from './pages/category.vue';
import ProfilePage from './pages/profile.vue';
import LoginPage from './pages/login.vue';
import FormPage from './pages/form.vue';
import DynamicRoutePage from './pages/dynamic-route.vue';
import NotFoundPage from './pages/not-found.vue';

import PanelLeftPage from './pages/panel-left.vue';

export default [
  {
    path: '/login/',
    component: LoginPage,
  },
  {
    path: '/panel-left/',
    component: PanelLeftPage,
  },
  {
    path: '/',
    component: DashboardPage,
  },
  {
    path: '/order/',
    component: OrderPage,
    options: {
      props: {
        order_id: 0,
      },
    }
  },
  {
    path: '/order-history/',
    component: OrderHistoryPage,
  },
  {
    path: '/about/',
    component: AboutPage,
  },
  {
    path: '/ecobox/',
    component: EcoboxPage,
  },
  {
    path: '/ecobox-buy/',
    component: EcoboxBuyPage,
  },
  {
    path: '/ecobox-order-list/',
    component: EcoboxOrderListPage,
  },
  {
    path: '/coupon/',
    component: CouponPage,
  },
  {
    path: '/account/',
    component: AccountPage,
  },
  {
    path: '/menu/',
    component: MenuPage,
  },
  {
    path: '/dish-edit/',
    component: DishEditPage,
    options: {
      props: {
        dish_id: 0,
        action: ''
      },
    }
  },
  {
    path: '/specs/',
    component: SpecsPage,
    options: {
      props: {
        dish_id: 0
      },
    }
  },
  {
    path: '/category/',
    component: CategoryPage,
  },
  {
    path: '/profile/',
    // path: '/',
    component: ProfilePage
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];
