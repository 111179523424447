<template>
  <f7-page style="background-color: #fff;">
    <f7-navbar>
      <f7-nav-left>
        <f7-link icon-ios="f7:menu" icon-md="material:menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title>余额提现</f7-nav-title>
    </f7-navbar>
    <div class="balance-wrapper bg-color-blue display-flex justify-content-space-evenly align-items-center" style="flex-direction: column;">
      <div class="balance">
        <div class="description">
          已入账金额
        </div>
        <div class="amount">
          {{this.formatPrice(this.account.balance)}}
        </div>
        <div class="description small">
          * 提现金额将在三个工作日内转帐到您的指定账户
        </div>
      </div>
      <div class="withdraw">
        <f7-button color="green" fill raised style="width: 80px;" @click="withdraw()">提现</f7-button>
      </div>
    </div>
    <f7-toolbar tabbar bottom>
      <f7-link tab-link="#tab-1" tab-link-active>日账单</f7-link>
      <f7-link tab-link="#tab-2">提现记录</f7-link>
    </f7-toolbar>
    <f7-tabs>
      <f7-tab id="tab-1" tab-active>
        <f7-block-title>日账单</f7-block-title>
        <f7-list media-list>
          <f7-list-item swipeout :title="bill.date | moment('MM月DD日')" :after="formatPrice(bill.amount)" v-for="bill in bills" :key="bill.date">
            <f7-swipeout-actions right>
              <f7-swipeout-button color="orange" @click="printBill(bill)">打印</f7-swipeout-button>
            </f7-swipeout-actions>
          </f7-list-item>
        </f7-list>
      </f7-tab>
      <f7-tab id="tab-2">
        <f7-block-title>提现记录</f7-block-title>
        <f7-list media-list>
          <f7-list-item :title="formatPrice(log.real_amount)" :after="formatState(log.state)" :footer="log.created_time | moment('YYYY年MM月DD日')" v-for="log in logs" :key="log.id"></f7-list-item>
        </f7-list>
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>

<script>
import invoke from '../libs/invoke'

export default {
  data() {
    return {
      account: {},
      logs: [],
      bills: []
    }
  },
  async mounted() {
    await this.fetchData()
  },
  methods: {
    formatState(value) {
      return ({
        REQUESTED: '处理中',
        RESOLVED: '提现成功',
        REJECTED: '提现失败'
      })[value]
    },
    formatPrice(value) {
      let val = (value / 100).toFixed(2)
      return '¥' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    printBill(bill) {
      window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({
        method: 'printBill',
        params: { bill }
      }))
    },
    async fetchData() {
      this.account = await invoke('account.getAccount')
      this.logs = await invoke('account.getAccountLogs')
      this.bills = await invoke('account.getDailyBill', { limit: 100 })
    },
    async withdraw() {
      this.$f7.dialog.prompt(`请填写提现金额，最低¥100元，最多${this.formatPrice(this.account.balance)}元`, '提现', async (value) => {
        await invoke('account.withdraw', {
          amount: value * 100
        })
        await this.fetchData()
      })
    }
  }
}
</script>

<style scoped>
.balance-wrapper {
  height: 250px;
  background-color: var(--f7-theme-color-bg-color);
}
.balance-wrapper>.balance {
  text-align: center;
}
.balance-wrapper>.balance>.description {
  color: #fff;
}
.balance-wrapper>.balance>.amount {
  font-size: 48px;
  color: #fff;
}
.balance-wrapper>.balance>.description.small {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.8)
}
</style>