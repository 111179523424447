<template>
  <f7-card class="new-order-card">
    <f7-card-header class="justify-content-flex-start">
      <div class="new-order-sn"><f7-chip :text="`#${order.serial_no}`" color="blue" style="font-size: 14px;"></f7-chip></div>
      <div class="new-order-pickuptime">{{order.take_time | moment("MM月YY日 a hh:mm")}} 取餐</div>
      <div class="new-order-state">{{order.state | formatOrderState}}</div>
    </f7-card-header>
    <f7-card-content :padding="false" class="order-detail">
      <div class="padding order-detail-contact display-flex align-items-center justify-content-space-between">
        <div class="name"><h2>{{order.takename}}</h2></div>
        <div class="mobile">{{order.phonenum}}</div>
      </div>
      <div class="order-detail-info" style="padding: 14px 16px;">
        <div class="count">商品({{order.order_dishes.length}})</div>
        <div class="note" v-if="order.note"><span class="title">备注：</span>{{order.note}}</div>
      </div>
      <div class="order-detail-items">
        <div class="data-table">
          <table>
            <tbody>
              <tr v-for="item in order.order_dishes" :key="item.id">
                <td class="label-cell" style="padding-top: 14px; padding-bottom: 14px;">
                  <span>{{item.dish_name}}</span>
                  <div v-for="attr in item.order_dish_spec_attrs" :key="attr.id" style="font-size: 12px;" class="text-color-orange">+ {{attr.spec_attr_name}}</div>
                </td>
                <td class="numeric-cell" style="width: 80px; padding-right: 0px;"><b>x{{item.count}}</b></td>
                <td class="numeric-cell" style="width: 100px; padding-left: 0px;">¥{{item.actual_amount}}</td>
              </tr>
              <tr v-if="(boxa_count + boxb_count + boxc_count) > 0">
                <td class="label-cell"><b>餐盒({{boxa_count + boxb_count + boxc_count}})</b></td>
                <td class="numeric-cell"></td>
                <td class="numeric-cell"></td>
              </tr>
              <tr v-if="boxa_count > 0">
                <td class="label-cell">大圆</td>
                <td class="numeric-cell" style="width: 80px; padding-right: 0px;"><b>x{{boxa_count}}</b></td>
                <td class="numeric-cell" style="width: 100px; padding-left: 0px;">¥{{(boxa_count * 2).toFixed(2)}}</td>
              </tr>
              <tr v-if="boxb_count > 0">
                <td class="label-cell">小圆</td>
                <td class="numeric-cell" style="width: 80px; padding-right: 0px;"><b>x{{boxb_count}}</b></td>
                <td class="numeric-cell" style="width: 100px; padding-left: 0px;">¥{{(boxb_count * 2).toFixed(2)}}</td>
              </tr>
              <tr v-if="boxc_count > 0">
                <td class="label-cell">方盒</td>
                <td class="numeric-cell" style="width: 80px; padding-right: 0px;"><b>x{{boxc_count}}</b></td>
                <td class="numeric-cell" style="width: 100px; padding-left: 0px;">¥{{(boxc_count * 2).toFixed(2)}}</td>
              </tr>
              <tr v-for="coupon in order.coupons" :key="coupon.id">
                <td class="label-cell" style="font-weight: bold;">{{['优惠券', '满减'][coupon.coupon_rule.method]}}</td>
                <td class="numeric-cell"></td>
                <td class="numeric-cell">-¥{{coupon.coupon_rule.amountoff_money}}</td>
              </tr>
              <tr style="font-weight: bold;">
                <td class="label-cell">本单收入</td>
                <td class="numeric-cell"></td>
                <td class="numeric-cell" style="color: var(--f7-color-orange);">¥{{order.actual_amount}}</td>
              </tr>
              <tr v-if="order.status !== 1 && order.order_action_logs.length > 0" style="color: var(--f7-block-title-text-color);">
                <td class="label-cell">订单历史</td>
                <td class="label-cell"></td>
                <td class="label-cell"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Timeline -->
      <div class="timeline" v-if="order.status !== 1 && order.order_action_logs.length > 0">
        <!-- Timeline item -->
        <div class="timeline-item">
          <div class="timeline-item-date">{{order.create_time | moment('MM月DD日')}}</div>
          <div class="timeline-item-divider"></div>
          <div class="timeline-item-content">
            <div class="timeline-item-inner">
              <div class="timeline-item-time">{{order.create_time | moment('a hh:mm')}}</div>
              <div class="timeline-item-title">创建订单</div>
            </div>
          </div>
        </div>
        <div class="timeline-item" v-for="item in order.order_action_logs" :key="item.key">
          <div class="timeline-item-date">{{item.create_time | moment('MM月DD日')}}</div>
          <div class="timeline-item-divider"></div>
          <div class="timeline-item-content">
            <div class="timeline-item-inner">
              <div class="timeline-item-time">{{item.create_time | moment('a hh:mm')}}</div>
              <div class="timeline-item-title">{{item.state_to | formatOrderState}}</div>
              <div class="timeline-item-text" v-if="item.description">描述: {{item.description}}</div>
            </div>
          </div>
        </div>
      </div>
    </f7-card-content>
  </f7-card>
</template>

<script>
import _ from 'lodash'

export default {
  props: ['order'],
  computed: {
    boxa_count: function() {
      return _.sumBy(this.order.order_dishes, (v) => {
        return v.boxa_num * v.count
      })
    },
    boxb_count: function() {
      return _.sumBy(this.order.order_dishes, (v) => {
        return v.boxb_num * v.count
      })
    },
    boxc_count: function() {
      return _.sumBy(this.order.order_dishes, (v) => {
        return v.boxc_num * v.count
      })
    }
  }
}
</script>

<style scoped>
.data-table {
  --f7-table-label-cell-padding-horizontal: 16px;
  --f7-table-cell-padding-horizontal: 16px;
  --f7-table-edge-cell-padding-horizontal: 16px;
}
.card {
  --f7-card-margin-vertical: 16px;
  --f7-card-margin-horizontal: 14px;
}
.new-order-card {
  /* padding-top: 20px; */
}
.new-order-sn span.sn {
  font-size: 28px;
  font-weight: bold;
}
.note {
  background-color: var(--f7-page-bg-color);
  padding:8px;
  border-radius: 5px;
  font-size: 12px;
}
.new-order-pickuptime {
  flex: 1;
  margin-left: 10px;
  font-weight: bold;
  font-size: 14px;
}
.new-order-state {
  font-weight: bold;
  font-size: 14px;
}
.order-detail-info .count {
  /* font-size: 21px; */
  font-weight: bold;
  margin-bottom: 10px;
}
.order-detail-info .count:last-of-type {
  margin-bottom: 0;
}
.order-detail-info .title {
  color: var(--f7-color-orange);
}
.order-detail-contact {
  position: relative;
  font-weight: bold;
}
.order-detail-contact:after {
  content: '';
  position: absolute;
  background-color: var(--f7-card-header-border-color);
  display: block;
  z-index: 15;
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  transform-origin: 50% 100%;
  transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
}
.order-detail .name h2 {
  margin: 0;
}
.timeline {
  position: relative;
  margin: 0;
  padding: 18px 16px;
}

.timeline:before {
  content: '';
  position: absolute;
  background-color: var(--f7-table-cell-border-color);
  display: block;
  z-index: 15;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  height: 1px;
  width: 100%;
  transform-origin: 50% 0%;
  transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
}

.timeline-item-date {
  font-size: 12px;
}
.timeline-item-title {
  font-size: 12px;
}
.timeline-item-inner {
  box-shadow: var(--f7-card-box-shadow);
}
.timeline-item-date {
  width: 30vw;
}
</style>