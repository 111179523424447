<template>
  <f7-page>
    <f7-navbar title="订单详情" back-link="Back"></f7-navbar>
    <order-card :order="order" v-if="order" class="order-order-card"></order-card>
    <f7-toolbar position="bottom" v-if="order && order.state !== 'CANCELED'" style="height: calc(var(--f7-toolbar-height) + var(--f7-safe-area-bottom) + 4px);">
      <div style="flex: 1;" v-if="order && order.state === 'UNACCEPTED'">
        <f7-row style="flex: 1; padding: 14px;">
          <f7-col width="100"><f7-button fill @click="doAction('confirm')">接单</f7-button></f7-col>
        </f7-row>
      </div>
      <div style="flex: 1;" v-if="order && order.state === 'ACCEPTED'">
        <f7-row style="flex: 1; padding: 14px;">
          <f7-col width="25"><f7-button fill color="orange" @click="printOrder(order)">打印</f7-button></f7-col>
          <f7-col width="75"><f7-button fill color="blue" @click="doAction('pickup')">通知取餐</f7-button></f7-col>
        </f7-row>
      </div>
      <div style="flex: 1;" v-if="order && order.state === 'WAITING'">
        <f7-row style="flex: 1; padding: 14px;">
          <f7-col width="25"><f7-button fill color="orange" @click="printOrder(order)">打印</f7-button></f7-col>
          <f7-col width="75"><f7-button fill color="blue" @click="doAction('finsh')">完成订单</f7-button></f7-col>
        </f7-row>
      </div>
      <div style="flex: 1;" v-if="order && order.state === 'CANCELING'">
        <f7-row style="flex: 1; padding: 14px;">
          <f7-col width="25"><f7-button fill color="red" @click="doAction('cancel.reject')">拒绝</f7-button></f7-col>
          <f7-col width="75"><f7-button fill color="blue" @click="doAction('cancel.confirm')">取消订单 & 退款</f7-button></f7-col>
        </f7-row>
      </div>
      <div style="flex: 1;" v-if="order && order.state === 'CLOSED'">
        <f7-row style="flex: 1; padding: 14px;">
          <f7-col width="100"><f7-button fill color="orange" @click="printOrder(order)">打印</f7-button></f7-col>
        </f7-row>
      </div>
    </f7-toolbar>
  </f7-page>
</template>

<script>
import { EventBus } from '../bus'
import OrderCard from '../components/order-card'
import invoke from '../libs/invoke'

export default {
  props: ['order_id'],
  data() {
    return {
      action: 'confirm',
      order: null,
      confirmSuccessMessage: this.$f7.notification.create({
        title: 'Sesame Eats',
        titleRightText: '刚刚',
        text: '确认订单成功',
        closeTimeout: 2000,
      }),
      pickupSuccessMessage: this.$f7.notification.create({
        title: 'Sesame Eats',
        titleRightText: '刚刚',
        text: '成功通知客户取餐，请留意',
        closeTimeout: 2000,
      }),
      finshSuccessMessage: this.$f7.notification.create({
        title: 'Sesame Eats',
        titleRightText: '刚刚',
        text: '订单流程已结束',
        closeTimeout: 2000,
      }),
      cancelConfirmSuccessMessage: this.$f7.notification.create({
        title: 'Sesame Eats',
        titleRightText: '刚刚',
        text: '订单已取消',
        closeTimeout: 2000,
      }),
      cancelRejectSuccessMessage: this.$f7.notification.create({
        title: 'Sesame Eats',
        titleRightText: '刚刚',
        text: '订单已拒绝退单',
        closeTimeout: 2000,
      })
    }
  },
  methods: {
    async fetchData() {
      this.order = await invoke('order.getOrder', { order_id: this.order_id })
    },
    printOrder(order) {
      window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({
        method: 'print',
        params: { order }
      }))
    },
    async doAction(action) {
      switch (action) {
        case 'pickup':
          await invoke('order.setOrderWaiting', { order_id: this.order_id })
          this.pickupSuccessMessage.open()
          break;
        case 'finsh':
          await invoke('order.setOrderClosed', { order_id: this.order_id })
          this.finshSuccessMessage.open()
          break;
        case 'cancel.confirm':
          await invoke('order.cancelOrderAction', { order_id: this.order_id, action: 1 })
          this.cancelConfirmSuccessMessage.open()
          const checked_canceled_order = JSON.parse(localStorage.getItem('checked_canceled_order') || '[]')
          localStorage.setItem('checked_canceled_order', JSON.stringify([...checked_canceled_order, this.order.id]))
          break;
        case 'cancel.reject':
          await invoke('order.cancelOrderAction', { order_id: this.order_id, action: 2 })
          this.cancelRejectSuccessMessage.open()
          break;
      }
      this.$f7router.back()
    }
  },
  async mounted() {
    await this.fetchData()
  },
  components: {
    OrderCard
  }
}
</script>

<style scoped>
.order-order-card {
  margin-bottom: 26px;
}
</style>