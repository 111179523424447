<template>
  <f7-page :page-content="false" @page:reinit="fetchData()">
    <f7-navbar>
      <f7-nav-left>
        <f7-link icon-ios="f7:menu" icon-md="material:menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title>订单处理</f7-nav-title>
    </f7-navbar>
    <f7-toolbar tabbar labels position="top">
      <f7-link tab-link="#dashboard-tab1" icon-md="material:bookmark" :icon-badge="unaccepted_orders.length" text="新订单" badge-color="red" tab-link-active></f7-link>
      <f7-link tab-link="#dashboard-tab2" icon-md="material:alarm" :icon-badge="accpeted_orders.length" text="制作中" badge-color="red"></f7-link>
      <f7-link tab-link="#dashboard-tab3" icon-md="material:check" :icon-badge="waiting_orders.length" text="待取餐" badge-color="red"></f7-link>
      <f7-link tab-link="#dashboard-tab4" icon-md="material:cancel" :icon-badge="canceling_orders.filter((v) => !v.checked).length" text="取消订单" badge-color="red"></f7-link>
    </f7-toolbar>
    <f7-tabs>
      <f7-tab id="dashboard-tab1" tab-active class="page-content" @tab:hide="showToolbarTab=false" @tab:show="showToolbarTab=true">
        <transition name="list" v-if="unaccepted_orders.length > 0">
          <order-card :order="currentOrder" class="dashboard-order-card" v-if="currentOrder"></order-card>
        </transition>
        <f7-block  v-if="unaccepted_orders.length === 0">
          <p class="text-align-center" style="color: var(--f7-block-title-text-color);">您还没有新订单，清耐心等待</p>
        </f7-block>
      </f7-tab>
      <f7-tab id="dashboard-tab2" class="page-content">
        <f7-block-title v-if="accpeted_orders.length > 0">点击列表查看详情</f7-block-title>
        <f7-list media-list>
          <f7-list-item
            swipeout
            link="#"
            @click="go(order.id)"
            v-for="order in accpeted_orders"
            :header="order.create_time | moment('MM月DD日 a hh:mm')"
            :title="`#${order.serial_no} - ${order.takename}`"
            :after="order.phonenum"
            :key="order.id"
          >
            <div slot="text">
              <div v-for="item in order.order_dishes.slice(0, 2)" :key="item.id">{{item.dish_name}}<span v-for="attr in item.order_dish_spec_attrs" :key="attr.id">[{{attr.spec_attr_name}}]</span> x {{item.count}}</div>
            </div>
            <f7-swipeout-actions left>
              <f7-swipeout-button :close="true" color="blue" @click="pickupSheet(order)" sheet-open=".demo-sheet-swipe-to-step">通知取餐</f7-swipeout-button>
            </f7-swipeout-actions>
          </f7-list-item>
        </f7-list>
        <f7-block  v-if="accpeted_orders.length === 0">
          <p class="text-align-center" style="color: var(--f7-block-title-text-color);">您还没有制作中订单</p>
        </f7-block>
      </f7-tab>
      <f7-tab id="dashboard-tab3" class="page-content">
        <f7-block-title v-if="waiting_orders.length > 0">点击列表查看详情</f7-block-title>
        <f7-list media-list>
          <f7-list-item
            swipeout
            link="#"
            @click="go(order.id)"
            v-for="order in waiting_orders"
            :header="order.create_time | moment('MM月DD日 a hh:mm')"
            :title="`#${order.serial_no} - ${order.takename}`"
            :after="order.phonenum"
            :key="order.id"
          >
            <div slot="text">
              <div v-for="item in order.order_dishes.slice(0, 2)" :key="item.id">{{item.dish_name}}<span v-for="attr in item.order_dish_spec_attrs" :key="attr.id">[{{attr.spec_attr_name}}]</span> x {{item.count}}</div>
            </div>
            <f7-swipeout-actions left>
              <f7-swipeout-button :close="true" color="orange" @click="finshSheet(order)" sheet-open=".demo-sheet-swipe-to-step">已取走</f7-swipeout-button>
            </f7-swipeout-actions>
          </f7-list-item>
        </f7-list>
        <f7-block  v-if="waiting_orders.length === 0">
          <p class="text-align-center" style="color: var(--f7-block-title-text-color);">您还没有待取餐订单</p>
        </f7-block>
      </f7-tab>
      <f7-tab id="dashboard-tab4" class="page-content" @tab:show="onCancelTabShow">
        <f7-block-title v-if="canceling_orders.length > 0">点击列表查看详情</f7-block-title>
        <f7-list media-list>
          <f7-list-item
            swipeout
            link="#"
            @click="go(order.id)"
            v-for="order in canceling_orders"
            :header="order.create_time | moment('MM月DD日 a hh:mm')"
            :title="`#${order.serial_no} - ${order.takename}`"
            :key="order.id"
            :badge="order.state | formatOrderState"
            :badge-color="order.state === 'CANCELED' ? 'teal' : 'red'"
          >
            <div slot="text">
              <div>{{order.phonenum}}</div>
              <div v-for="item in order.order_dishes.slice(0, 2)" :key="item.id">{{item.dish_name}}<span v-for="attr in item.order_dish_spec_attrs" :key="attr.id">[{{attr.spec_attr_name}}]</span> x {{item.count}}</div>
            </div>
          </f7-list-item>
        </f7-list>
        <f7-block v-if="canceling_orders.length === 0">
          <p class="text-align-center" style="color: var(--f7-block-title-text-color);">您还没有待处理退单</p>
        </f7-block>
      </f7-tab>
    </f7-tabs>

    <!-- Processed Orders Sheet -->
    <f7-sheet
      style="height:auto; --f7-sheet-bg-color: #fff;"
      :opened="processedSheetOpened"
      @sheet:closed="processedSheetOpened = false"
      swipe-to-close
      swipe-to-step
      backdrop
    >
      <!-- Initial swipe step sheet content -->
      <div class="sheet-modal-swipe-step">
        <div class="display-flex padding justify-content-space-between align-items-center">
          <div style="font-size: 18px"><b>期望取餐</b></div>
          <div style="font-size: 14px"><b>{{currentProcessedOrder.take_time | moment('MM月DD日 a hh:ss')}}</b></div>
        </div>
        <div class="padding-horizontal padding-bottom">
          <f7-button large fill @click="pickup(currentProcessedOrder)">通知取餐</f7-button>
          <div class="margin-top text-align-center">上滑查看购物车</div>
        </div>
      </div>
      <!-- Rest of the sheet content that will opened with swipe -->
      <div class="data-table">
        <table>
          <thead>
            <tr>
              <th class="label-cell">商品</th>
              <th class="numeric-cell">数量</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in currentProcessedOrder.order_dishes" :key="item.id">
              <td class="label-cell">{{item.dish_name}}<span v-for="attr in item.order_dish_spec_attrs" :key="attr.id">[{{attr.spec_attr_name}}]</span></td>
              <td class="numeric-cell">x{{item.count}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </f7-sheet>

    <!-- Waiting Orders Sheet -->
    <f7-sheet
      style="height:auto; --f7-sheet-bg-color: #fff;"
      :opened="waitingSheetOpened"
      @sheet:closed="waitingSheetOpened = false"
      swipe-to-close
      swipe-to-step
      backdrop
    >
      <!-- Initial swipe step sheet content -->
      <div class="sheet-modal-swipe-step">
        <div class="display-flex padding justify-content-space-between align-items-center">
          <div style="font-size: 18px"><b>期望取餐</b></div>
          <div style="font-size: 14px"><b>{{currentWaitingOrder.take_time | moment('MM月DD日 a hh:ss')}}</b></div>
        </div>
        <div class="padding-horizontal padding-bottom">
          <f7-button large fill @click="finsh(currentWaitingOrder)">完成订单</f7-button>
          <div class="margin-top text-align-center">上滑查看购物车</div>
        </div>
      </div>
      <!-- Rest of the sheet content that will opened with swipe -->
      <div class="data-table">
        <table>
          <thead>
            <tr>
              <th class="label-cell">商品</th>
              <th class="numeric-cell">数量</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in currentWaitingOrder.order_dishes" :key="item.id">
              <td class="label-cell">{{item.dish_name}}<span v-for="attr in item.order_dish_spec_attrs" :key="attr.id">[{{attr.spec_attr_name}}]</span></td>
              <td class="numeric-cell">x{{item.count}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </f7-sheet>
    <f7-actions id="reject-order">
      <f7-actions-group>
        <f7-actions-label>拒绝理由</f7-actions-label>
        <f7-actions-button @click="reject(currentOrder, '菜品售光')">菜品售光</f7-actions-button>
        <f7-actions-button @click="reject(currentOrder, '餐厅已打烊')">餐厅已打烊</f7-actions-button>
        <f7-actions-button @click="reject(currentOrder, '餐厅太忙')">餐厅太忙</f7-actions-button>
        <f7-actions-button @click="reject(currentOrder, '重复订单')">重复订单</f7-actions-button>
      </f7-actions-group>
      <f7-actions-group>
        <f7-actions-button color="red">取消</f7-actions-button>
      </f7-actions-group>
    </f7-actions>
    <f7-toolbar position="bottom" v-if="showToolbar" style="height: calc(var(--f7-toolbar-height) + var(--f7-safe-area-bottom) + 4px);">
      <f7-row style="flex: 1; padding: 14px;">
        <f7-col width="25"><f7-button outline color="red" actions-open="#reject-order">拒单</f7-button></f7-col>
        <f7-col width="75"><f7-button outline @click="confirm(unaccepted_orders[0])">接单</f7-button></f7-col>
      </f7-row>
    </f7-toolbar>
  </f7-page>
</template>

<script>
import { data } from '../fixture'
import { EventBus } from '../bus'
import _ from 'lodash'
import OrderCard from '../components/order-card'
import invoke from '../libs/invoke'

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export default {
  data() {
    return {
      /** Notifications */
      confirmSuccessMessage: this.$f7.notification.create({
        title: 'Sesame Eats',
        titleRightText: '刚刚',
        text: '确认订单成功',
        closeTimeout: 2000,
      }),
      pickupSuccessMessage: this.$f7.notification.create({
        title: 'Sesame Eats',
        titleRightText: '刚刚',
        text: '成功通知客户取餐，请留意',
        closeTimeout: 2000,
      }),
      finshSuccessMessage: this.$f7.notification.create({
        title: 'Sesame Eats',
        titleRightText: '刚刚',
        text: '订单流程已结束',
        closeTimeout: 2000,
      }),
      cancelConfirmSuccessMessage: this.$f7.notification.create({
        title: 'Sesame Eats',
        titleRightText: '刚刚',
        text: '订单已取消',
        closeTimeout: 2000,
      }),
      cancelRejectSuccessMessage: this.$f7.notification.create({
        title: 'Sesame Eats',
        titleRightText: '刚刚',
        text: '订单已拒绝退单',
        closeTimeout: 2000,
      }),

      /** Sheets */
      unprocessedSheetOpened: false,
      processedSheetOpened: false,
      waitingSheetOpened: false,
      cancelingSheetOpened: false,

      /** Current unaccepted order to show */
      currentOrder: null,

      /** Current sheet order */
      currentUnprocessedOrder: {},
      currentProcessedOrder: {},
      currentWaitingOrder: {},
      currentCancelingOrder: {},

      /** Orders */
      unaccepted_orders: [],
      accpeted_orders: [],
      waiting_orders: [],
      canceling_orders: [],

      /** Bottom toolbar */
      showToolbarTab: true
    }
  },
  computed: {
    showToolbar: {
      get() {
        return this.unaccepted_orders.length > 0 && this.showToolbarTab
      },
      set(value) {}
    }
  },
  methods: {
    go(order_id, action) {
      this.$f7router.navigate(`/order/`, {
        props: {
          order_id
        }
      })
    },
    /** Show next order animation */
    nextOrder() {
      this.currentOrder = null
      setTimeout(() => {
        this.currentOrder = this.unaccepted_orders[0] || null
      }, 100)
    },
    confirmSheet(order) {
      this.currentUnprocessedOrder = order
      setTimeout(() => {
        this.unprocessedSheetOpened = true
      }, 0)
    },
    async confirm(order) {
      window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({
        method: 'print',
        params: { order }
      }))
      await invoke('order.setOrderAccepted', { order_id: order.id })
      await this.fetchData()
      this.unprocessedSheetOpened = false
      this.confirmSuccessMessage.open()
      this.nextOrder()
    },
    pickupSheet(order) {
      this.currentProcessedOrder = order
      setTimeout(() => {
        this.processedSheetOpened = true
      }, 0)
    },
    async pickup(order) {
      await invoke('order.setOrderWaiting', { order_id: order.id })
      await this.fetchData()
      // this.waitingOrders = [order, ...this.waitingOrders]
      this.processedSheetOpened = false
      this.pickupSuccessMessage.open()
    },
    finshSheet(order) {
      this.currentWaitingOrder = order
      setTimeout(() => {
        this.waitingSheetOpened = true
      }, 0)
    },
    async onCancelTabShow() {
      const checked_canceled_order = this.canceling_orders
        .filter((v) => v.state === 'CANCELED')
        .map((v) => v.id)
      localStorage.setItem('checked_canceled_order', JSON.stringify(checked_canceled_order))
      this.canceling_orders = this.canceling_orders.map((v) => {
        return Object.assign({}, v, {
          checked: checked_canceled_order.indexOf(v.id) >= 0
        })
      })
    },
    async finsh(order) {
      await invoke('order.setOrderClosed', { order_id: order.id })
      await this.fetchData()
      this.waitingSheetOpened = false
      this.finshSuccessMessage.open()
    },
    async reject(order, description) {
      await invoke('order.cancelOrder', { order_id: order.id, description })
      await this.fetchData()
      this.finshSuccessMessage.open()
      this.nextOrder()
    },
    async fetchData() {
      this.unaccepted_orders = await invoke('order.getOrders', { state: 'UNACCEPTED' })
      this.accpeted_orders = await invoke('order.getOrders', { state: 'ACCEPTED' })
      this.waiting_orders = await invoke('order.getOrders', { state: 'WAITING' })
      this.canceling_orders = await invoke('order.getTodayCancelOrders')
      const checked_canceled_order = JSON.parse(localStorage.getItem('checked_canceled_order') || '[]')
      this.canceling_orders = this.canceling_orders.map((v) => {
        return Object.assign({}, v, {
          checked: checked_canceled_order.indexOf(v.id) >= 0
        })
      })
      const autoPickup = localStorage.getItem('auto_pickup') === 'true'
      if (autoPickup) {
        await asyncForEach(this.unaccepted_orders, async (o) => {
          await this.confirm(o)
        })
      }
      this.currentOrder = this.unaccepted_orders[0]
    },
    async handleMessage(event) {
      const data = JSON.parse(event.data)
      if (data.method === 'onOrder') {
        await this.fetchData()
      }
    }
  },
  async mounted() {
    const token = localStorage.getItem('token')
    if (!token) {
      return this.$f7router.navigate(`/login/`)
    }
    const profile = await invoke('rst.getProfile')
    window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({
      method: 'init',
      params: profile
    }))
    document.addEventListener('message', this.handleMessage)
    if (global.intervalHandle) { clearInterval(global.intervalHandle) }
    global.intervalHandle = setInterval(async () => {
      await this.fetchData()
    }, (process.env.NODE_ENV === 'production' ? 5 : 30) * 1000)

    await this.fetchData()
  },
  destroyed () {
    document.removeEventListener('message', this.handleMessage)
  },
  components: {
    OrderCard
  }
}
</script>

<style scoped>
.dashboard-card {
  margin-top: 20px;
}
.dashboard-col {
  /* padding: 10px 5px; */
  /* text-align: center; */
  flex: 1;
}
.dashboard-col .title {
  color: #666;
}
.dashboard-col .content {
  font-size: 24px;
}
.dashboard-col:nth-child(2) {
  border-left: 1px solid var(--f7-list-item-border-color);
}

.list-enter-active, .list-leave-active {
  transition: all 0.5s;
}
.list-enter /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(200px);
}

.list-leave-to {
  opacity: 0;
  transform: translateY(-200px);
}

.dashboard-order-card {
  margin-bottom: 76px;
}
</style>