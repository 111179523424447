<template>
  <f7-page
    infinite
    :infinite-distance="50"
    :infinite-preloader="showPreloader"
    @infinite="loadMore"
    style="background-color: #fff;"
  >
    <f7-navbar>
      <f7-nav-left>
        <f7-link icon-ios="f7:menu" icon-md="material:menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title>历史订单</f7-nav-title>
      <f7-nav-right>
        <f7-link class="searchbar-enable" data-searchbar=".order-search" icon-ios="f7:search" icon-aurora="f7:search" icon-md="material:search"></f7-link>
      </f7-nav-right>
      <f7-searchbar
        class="order-search"
        expandable
        search-container=".search-list"
        placeholder="订单号或手机号码"
        :custom-search="true"
        @change="onSearch"
      ></f7-searchbar>
    </f7-navbar>
    <f7-block-title v-if="history_orders.length > 0">点击列表查看详情</f7-block-title>
    <f7-block v-if="history_orders.length === 0">
      <p class="text-align-center" style="color: var(--f7-block-title-text-color);">没有找到历史订单记录</p>
    </f7-block>
    <f7-list
      class="search-list searchbar-found"
      media-list
    >
      <f7-list-item
        v-for="(order) in history_orders"
        media-item
        link="#"
        :title="`${order.takename}`"
        :after="order.phonenum"
        :key="order.id"
        @click="go(order.id)"
      >
        <div slot="header">
          <div>{{order.create_time | moment('YYYY年MM月DD日 a hh:mm')}}</div>
          <div>{{order.order_no.toUpperCase()}}</div>
        </div>
        <div slot="text">
          <span>{{order.state | formatOrderState}}</span>
        </div>
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>
<script>
  import invoke from '../libs/invoke'
  export default {
    data() {
      return {
        history_orders: [],
        allowInfinite: true,
        showPreloader: false,
        offset: 10,
        limit: 10,
        timeout: null,
        keyword: ''
      }
    },
    async mounted() {
      await this.fetchData()
    },
    methods: {
      async onSearch(evt) {
        this.offset = 10
        this.keyword = evt.target.value.toLowerCase()
        this.history_orders = await invoke('order.getHistory', { keyword: this.keyword })
        if (this.history_orders.length < this.limit) {
          this.allowInfinite = false
        } else {
          this.allowInfinite = true
        }
      },
      async fetchData() {
        this.history_orders = await invoke('order.getHistory')
      },
      async loadMore() {
        if (!this.allowInfinite) return
        this.allowInfinite = false
        this.showPreloader = true

        const moreOrders = await invoke('order.getHistory', { offset: this.offset, limit: this.limit, keyword: this.keyword })
        this.offset += this.limit

        this.history_orders = [...this.history_orders, ...moreOrders]

        if (moreOrders.length < this.limit) {
          this.showPreloader = false;
          return
        }

        this.allowInfinite = true;
      },
      go(order_id) {
        this.$f7router.navigate(`/order/`, {
          props: {
            order_id
          }
        })
      }
    },
  };
</script>
