const config = require('../config/' + process.env.NODE_ENV + '.js')

module.exports = (formData) => {
  return fetch(`${config.server}/oss/upload`, {
    method: 'POST',
    body: formData
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error) {
        throw new Error(res.error.message)
      } else {
        return res.result
      }
    })
}