<template>
  <f7-page style="background-color: #fff;" ptr @ptr:refresh="fetchData">
    <f7-navbar>
      <f7-nav-left>
        <f7-link icon-ios="f7:menu" icon-md="material:menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title>餐厅管理</f7-nav-title>
    </f7-navbar>
    <div class="dish-image display-flex">
      <img :src="profile.bgimg_url">
    </div>
    <f7-block-title medium>{{profile.name}}</f7-block-title>
    <f7-block-title>今日数据</f7-block-title>
    <f7-list>
      <f7-list-item title="订单数" :after="profile.stats.count || '无'"></f7-list-item>
      <f7-list-item title="营业额" :after="profile.stats.sales ? `¥${profile.stats.sales}` : '无'"></f7-list-item>
    </f7-list>
    <f7-block-title>餐厅状态</f7-block-title>
    <f7-list>
      <f7-list-item title="营业状态" :footer="['已关店', '开业中', '已关店'][profile.status]">
        <f7-icon slot="media" :f7="(profile.status === 1) ? 'lightbulb' : 'lightbulb_slash'"></f7-icon>
        <f7-toggle :checked="profile.status === 1" @toggle:change="switchState"></f7-toggle>
      </f7-list-item>
      <f7-list-item title="自动接单" :footer="['关闭', '开启'][autoPickup / 1]">
        <f7-icon slot="media" :f7="autoPickup ? 'bolt_badge_a' : 'bolt_slash'"></f7-icon>
        <f7-toggle :checked="autoPickup" @toggle:change="switchAutoPickup"></f7-toggle>
      </f7-list-item>
      <f7-list-item :title="profile.address" :footer="profile.phonenum">
        <f7-icon slot="media" f7="person"></f7-icon>
      </f7-list-item>
      <f7-list-item :title="profile.short_desc">
        <f7-icon slot="media" f7="heart"></f7-icon>
      </f7-list-item>
      <f7-list-item :title="profile.prepare_time" footer="制作时长">
        <f7-icon slot="media" f7="timer"></f7-icon>
      </f7-list-item>
      <f7-list-item :title="profile.average_consume" footer="人均消费">
        <f7-icon slot="media" f7="money_yen_circle"></f7-icon>
      </f7-list-item>
      <f7-list-item link="#" @click="openPicker()" footer="营业时间">
        <f7-icon slot="media" f7="sun_min"></f7-icon>
        <div slot="title">
          {{profile.begin_time}} - {{profile.close_time}}
        </div>
      </f7-list-item>
      <li v-show="false">
        <div class="item-content item-input">
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="text" placeholder="Your iOS device" readonly="readonly" id="time-picker"/>
            </div>
          </div>
        </div>
      </li>
    </f7-list>
    <f7-block-title>餐厅描述</f7-block-title>
    <f7-block>
      <p>{{profile.long_desc}}</p>
    </f7-block>
  </f7-page>
</template>

<script>
import invoke from '../libs/invoke'
import moment from 'moment'

export default {
  data() {
    return {
      profile: {
        status: null,
        stats: {
          count: '0',
          sales: '0.00'
        }
      },
      timePicker: null,
      autoPickup: false
    }
  },
  async mounted() {
    await this.fetchData()
  },
  methods: {
    async fetchData(e, done) {
      this.profile = await invoke('rst.getProfile')
      const begin_time = moment('2015-01-01', 'YYYY-MM-DD').startOf('day').seconds(this.profile.begin_time)
      const close_time = moment('2015-01-01', 'YYYY-MM-DD').startOf('day').seconds(this.profile.end_time)

      this.profile.begin_time = begin_time.format('H:mm')
      this.profile.close_time = close_time.format('H:mm')
      this.autoPickup = global.localStorage.getItem('auto_pickup') === 'true'

      this.timePicker = this.$f7.picker.create({
        inputEl: '#time-picker',
        renderToolbar() {
          return '<div class="toolbar">' +
            '<div class="toolbar-inner">' +
              '<div class="left">' +
                '<a href="#" class="link sheet-close popover-close">取消</a>' +
              '</div>' +
              '<div class="right">' +
                '<a href="#" class="link save-time sheet-close popover-close">保存</a>' +
              '</div>' +
            '</div>' +
          '</div>';
        },
        on: {
          open: (picker) => {
            picker.$el.find('.save-time').on('click', async () => {
              const begin_time = {
                hour: picker.cols[0].value,
                minute: picker.cols[2].value
              }
              const close_time = {
                hour: picker.cols[4].value,
                minute: picker.cols[6].value
              }
              const begin_seconds = begin_time.hour * 60 * 60 + begin_time.minute * 60
              const close_seconds = close_time.hour * 60 * 60 + close_time.minute * 60

              await this.updateTime(begin_seconds, close_seconds)
              await this.fetchData()
            });
          },
        },
        value: [
          begin_time.hour(),
          begin_time.minute(),
          close_time.hour(),
          close_time.minute()
        ],
        cols: [
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 0; i <= 23; i += 1) { arr.push(i); }
              return arr;
            }()),
          },
          {
            divider: true,
            content: ':',
          },
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 0; i <= 59; i += 1) { arr.push(i); }
              return arr;
            }()),
            displayValues: (function createValues() {
              const arr = [];
              for (let i = 0; i <= 59; i += 1) { arr.push(i < 10 ? `0${i}` : i); }
              return arr;
            }()),
          },
          {
            divider: true,
            content: '&nbsp;-&nbsp;',
          },
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 0; i <= 23; i += 1) { arr.push(i); }
              return arr;
            }()),
          },
          {
            divider: true,
            content: ':',
          },
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 0; i <= 59; i += 1) { arr.push(i); }
              return arr;
            }()),
            displayValues: (function createValues() {
              const arr = [];
              for (let i = 0; i <= 59; i += 1) { arr.push(i < 10 ? `0${i}` : i); }
              return arr;
            }()),
          },
        ],
      })
      done && done()
    },
    openPicker() {
      this.timePicker.open()
    },
    async switchState(e) {
      if (e / 1 === this.profile.status % 2) { return }
      await invoke('rst.switchState', { action: e === true ? 1 : 2 })
      await this.fetchData()
      this.$f7.dialog.alert('营业状态已更新', '操作提示')
    },
    async switchAutoPickup(e) {
      if (e === this.autoPickup) { return }
      global.localStorage.setItem('auto_pickup', e)
      await this.fetchData()
      this.$f7.dialog.alert('自动接单状态已更新', '操作提示')
    },
    async updateTime(begin_time, close_time) {
      await invoke('rst.updateRstTime', { begin_time, close_time })
      this.$f7.dialog.alert('营业时间更新成功', '操作提示')
    }
  }
}
</script>

<style scoped>
.dish-image img {
  --div-width: calc(100vw);
	width: var(--div-width);
	height: var(--div-width);
  display: block;
}
</style>