<template>
  <f7-page style="background-color: #fff;">
    <f7-navbar title="加购餐盒" back-link="Back"></f7-navbar>
    <f7-block-title>购买数量</f7-block-title>
    <f7-list>
      <f7-list-item :title="`大圆`">
        <f7-stepper :value="amount_a" small raised :step="10" :max="999" slot="after" @stepper:change="((value) => { this.amount_a = value })"></f7-stepper>
      </f7-list-item>
      <f7-list-item :title="`小圆`">
        <f7-stepper :value="amount_b" small raised :step="10" :max="999" slot="after" @stepper:change="((value) => { this.amount_b = value })"></f7-stepper>
      </f7-list-item>
      <f7-list-item :title="`方盒`">
        <f7-stepper :value="amount_c" small raised :step="10" :max="999" slot="after" @stepper:change="((value) => { this.amount_c = value })"></f7-stepper>
      </f7-list-item>
    </f7-list>
    <f7-block>
      <f7-row tag="p">
        <f7-col tag="span">
          <f7-button raised fill @click="submit()">提交</f7-button>
        </f7-col>
      </f7-row>
    </f7-block>
  </f7-page>
</template>

<script>
import invoke from '../libs/invoke'

export default {
  data() {
    return {
      amount_a: 0,
      amount_b: 0,
      amount_c: 0
    }
  },
  methods: {
    async submit() {
      this.$f7.dialog.confirm('确认提交餐盒订单吗？', '确认', async () => {
        await invoke('container.createOrder', {
          amount_a: this.amount_a,
          amount_b: this.amount_b,
          amount_c: this.amount_c
        })
        this.$f7router.back()
      })
    }
  }
}
</script>