// Import Vue
import Vue from 'vue';

import moment from 'moment'
import 'moment/locale/zh-cn'

// Import F7
import Framework7 from 'framework7/framework7.esm.bundle.js';

// Import F7 Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import F7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import IconsStyles from './css/icons.css';
import AppStyles from './css/app.css';

// Import App Component
import App from './app.vue';

// Init F7 Vue Plugin
Framework7.use(Framework7Vue)
Vue.use(require('vue-moment'), {
  moment
})

Vue.filter('formatOrderState', function (value) {
  if (!value) return ''
  value = value.toString()
  const states = {
    'UNACCEPTED': '待接单',
    'ACCEPTED': '制作中',
    'WAITING': '等待取餐',
    'CLOSED': '已完成',
    'CANCELING': '退单处理中',
    'CANCELED': '已退单',
  }
  return states[value]
})

Array.prototype.remove = function() {
  var what, a = arguments, L = a.length, ax;
  while (L && this.length) {
      what = a[--L];
      while ((ax = this.indexOf(what)) !== -1) {
          this.splice(ax, 1);
      }
  }
  return this;
};

// Init App
new Vue({
  el: '#app',
  template: '<app/>',

  // Register App Component
  components: {
    app: App
  }
});
