<template>
  <f7-page>
    <f7-navbar title="规格" back-link="Back"></f7-navbar>
    <f7-list>
      <f7-list-group
        v-for="spec in specs"
        :key="spec.id"
      >
        <li class="list-group-title display-flex align-items-center">
          <div class="spec-name" style="flex: 1;"><span>{{ spec.name }}</span></div>
          <div class="ctrl display-flex">
            <f7-button @click="createSpecAttr(spec.id)">添加属性</f7-button>
            <f7-button @click="removeSpec(spec.id)">删除规格</f7-button>
          </div>
        </li>
        <f7-list-item
          :title="attr.name"
          :after="`¥${attr.amount}`"
          link="#"
          swipeout
          @swipeout:deleted="onRemoveSpecAttr(attr.id)"
          v-for="attr in spec.spec_attrs"
          :key="attr.id"
          @click="updateSpecAttr(attr.id, attr.name)"
        >
          <f7-swipeout-actions right>
            <f7-swipeout-button delete confirm-title="确认操作" confirm-text="你确定要删除该分类吗">删除</f7-swipeout-button>
          </f7-swipeout-actions>
        </f7-list-item>
      </f7-list-group>
    </f7-list>
    <f7-fab position="right-bottom" slot="fixed" color="red" @click="createSpec()">
      <f7-icon ios="f7:add" aurora="f7:add" md="material:add"></f7-icon>
    </f7-fab>
  </f7-page>
</template>
<script>
import invoke from '../libs/invoke'
import _ from 'lodash'

export default {
  props: ['dish_id'],
  data() {
    return {
      specs: []
    }
  },
  async mounted() {
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      this.specs = await invoke('dish.getSpecs', { dish_id: this.dish_id })
    },
    createSpec() {
      this.$f7.dialog.prompt('请输入规格名称：', '添加规格', async (name) => {
        await invoke('dish.createSpec', { spec_name: name, dish_id: this.dish_id })
        await this.fetchData()
      })
    },
    removeSpec(spec_id) {
      this.$f7.dialog.confirm('删除规格将同时删除相关属性，确定删除？', '确认操作', async () => {
        await invoke('dish.removeSpec', { spec_id })
        await this.fetchData()
      })
    },
    createSpecAttr(spec_id) {
      this.$f7.dialog.prompt('请输入属性名称：', '添加属性', async (name) => {
        await invoke('dish.createSpecAttr', { spec_id, spec_attr_name: name, spec_attr_amount: 0})
        await this.fetchData()
      })
    },
    updateSpecAttr(spec_attr_id, spec_attr_name) {
      this.$f7.dialog.prompt('请输入属性价格：', '更新价格', async (spec_attr_amount) => {
        await invoke('dish.updateSpecAttr', { spec_attr_id, spec_attr_name, spec_attr_amount: spec_attr_amount / 1 })
        await this.fetchData()
      })
    },
    async onRemoveSpecAttr(spec_attr_id) {
      await invoke('dish.removeSpecAttr', { spec_attr_id })
      await this.fetchData()
    }
  },
}
</script>
<style scoped>

</style>