<template>
  <f7-page @page:reinit="reinit()" style="background-color: #fff;" ptr @ptr:refresh="fetchData">
    <f7-navbar>
      <f7-nav-left>
        <f7-link icon-ios="f7:menu" icon-md="material:menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title>优惠活动</f7-nav-title>
    </f7-navbar>
    <f7-block-title>点击列表查看详情</f7-block-title>
    <f7-list>
      <f7-list-group>
        <f7-list-item title="餐厅满减" group-title></f7-list-item>
        <f7-list-item
          v-for="rule in coupon_rules.filter((v) => v.method === 1)" :key="rule.id"
          :title="`满 ¥${rule.amountoff_limit} 减 ¥${rule.amountoff_money}`"
          :after="rule.state"
          popup-open=".limitoff-detail"
          @click="fetchLimitoffDetail(rule.id)"
          link="#"
        >
        </f7-list-item>
      </f7-list-group>
      <f7-list-group>
        <f7-list-item title="优惠券历史" group-title></f7-list-item>
        <f7-list-item
          v-for="rule in coupon_rules.filter((v) => v.method === 0)" :key="rule.id"
          :title="`满 ¥${rule.amountoff_limit} 减 ¥${rule.amountoff_money}`"
          :after="rule.state"
          popup-open=".coupon-detail"
          @click="fetchCouponDetail(rule.id)"
          link="#"
        >
        </f7-list-item>
      </f7-list-group>
    </f7-list>
    <f7-popup class="coupon-detail">
      <f7-page v-if="coupon_detail">
        <f7-navbar title="优惠券">
          <f7-nav-right>
            <f7-link popup-close style="font-size: 17px;">关闭</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block-title>统计数据</f7-block-title>
        <f7-list>
          <f7-list-item title="创建时间" :after="coupon_detail.create_time | moment('YYYY年MM月DD日')"></f7-list-item>
          <f7-list-item title="起始时间" :after="coupon_detail.begin_time | moment('YYYY年MM月DD日')"></f7-list-item>
          <f7-list-item title="结束时间" :after="coupon_detail.end_time | moment('YYYY年MM月DD日')"></f7-list-item>
          <f7-list-item title="总发放" :after="coupon_detail.count.toString()"></f7-list-item>
          <f7-list-item title="已使用" :after="coupon_detail.used.toString()"></f7-list-item>
          <f7-list-item title="未使用" :after="coupon_detail.unused.toString()"></f7-list-item>
        </f7-list>
        <f7-block-title>内容</f7-block-title>
        <f7-list>
          <f7-list-item title="满减门槛" :after="`¥${coupon_detail.amountoff_limit}`"></f7-list-item>
          <f7-list-item title="满减金额" :after="`¥${coupon_detail.amountoff_money}`"></f7-list-item>
        </f7-list>
      </f7-page>
    </f7-popup>
    <f7-popup class="limitoff-detail">
      <f7-page v-if="limitoff_detail">
        <f7-navbar title="餐厅满减">
          <f7-nav-right>
            <f7-link popup-close style="font-size: 17px;">关闭</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block-title>统计数据</f7-block-title>
        <f7-list>
          <f7-list-item title="创建时间" :after="limitoff_detail.create_time | moment('YYYY年MM月DD日')"></f7-list-item>
          <f7-list-item title="起始时间" :after="limitoff_detail.begin_time | moment('YYYY年MM月DD日')"></f7-list-item>
          <f7-list-item title="结束时间" :after="limitoff_detail.end_time | moment('YYYY年MM月DD日')"></f7-list-item>
          <f7-list-item title="已优惠订单数" :after="limitoff_detail.used.toString()"></f7-list-item>
        </f7-list>
        <f7-block-title>内容</f7-block-title>
        <f7-list>
          <f7-list-item title="满减门槛" :after="`¥${limitoff_detail.amountoff_limit}`"></f7-list-item>
          <f7-list-item title="满减金额" :after="`¥${limitoff_detail.amountoff_money}`"></f7-list-item>
        </f7-list>
      </f7-page>
    </f7-popup>
    <f7-popup class="create-coupon" :opened="couponPopupOpened" @popup:closed="couponPopupOpened = false">
      <f7-page>
        <f7-navbar title="发放优惠券">
          <f7-nav-right>
            <f7-link popup-close style="font-size: 17px;">取消</f7-link>
            <f7-link style="font-size: 17px;" @click="generateCoupon()">生成</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block-title>优惠券信息</f7-block-title>
        <f7-list no-hairlines-md v-if="coupon_form_data">
          <f7-list-input
            info="订单享受优惠的门槛金额"
            type="number"
            placeholder="优惠门槛"
            required
            validate
            :value="coupon_form_data.amountOffLimit"
            @input="coupon_form_data.amountOffLimit = ($event.target.value / 1)"
          >
          </f7-list-input>

          <f7-list-input
            info="订单优惠金额"
            type="number"
            placeholder="优惠金额"
            required
            validate
            :value="coupon_form_data.amountOffMoney"
            @input="coupon_form_data.amountOffMoney = ($event.target.value / 1)"
          >
          </f7-list-input>

          <f7-list-input
            info="起始日期"
            type="datepicker"
            placeholder="起始日期"
            readonly
            required
            validate
            :calendar-params="{
              closeOnSelect: true
            }"
            :value="coupon_form_data.beginTime"
            @calendar:change="coupon_form_data.beginTime = ($event[0].valueOf() / 1000)"
          ></f7-list-input>
          <f7-list-input
            info="结束日期"
            type="datepicker"
            placeholder="结束日期"
            :calendar-params="{
              closeOnSelect: true
            }"
            :value="coupon_form_data.endTime"
            @calendar:change="coupon_form_data.endTime = ($event[0].valueOf() / 1000)"
            readonly
            required
            validate
          ></f7-list-input>
          <f7-list-input
            info="优惠券描述"
            type="text"
            placeholder="优惠券描述"
            :value="coupon_form_data.intro"
            @input="coupon_form_data.intro = $event.target.value"
          >
          </f7-list-input>
        </f7-list>
      </f7-page>
    </f7-popup>
    <f7-popup class="create-limitoff" :opened="limitoffPopupOpened" @popup:closed="limitoffPopupOpened = false">
      <f7-page>
        <f7-navbar title="餐厅满减">
          <f7-nav-right>
            <f7-link popup-close style="font-size: 17px;">取消</f7-link>
            <f7-link style="font-size: 17px;" @click="generateLimitoff()">生成</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block-title>餐厅满减活动信息</f7-block-title>
        <f7-list no-hairlines-md v-if="limitoff_form_data">
          <f7-list-input
            info="订单享受优惠的门槛金额"
            type="number"
            placeholder="优惠门槛"
            required
            validate
            :value="limitoff_form_data.amountOffLimit"
            @input="limitoff_form_data.amountOffLimit = ($event.target.value / 1)"
          >
          </f7-list-input>

          <f7-list-input
            info="订单优惠金额"
            type="number"
            placeholder="优惠金额"
            required
            validate
            :value="limitoff_form_data.amountOffMoney"
            @input="limitoff_form_data.amountOffMoney = ($event.target.value / 1)"
          >
          </f7-list-input>

          <f7-list-input
            info="起始日期"
            type="datepicker"
            placeholder="起始日期"
            readonly
            required
            validate
            :value="limitoff_form_data.beginTime"
            @calendar:change="limitoff_form_data.beginTime = ($event[0].valueOf() / 1000)"
            :calendar-params="{
              closeOnSelect: true
            }"
          ></f7-list-input>
          <f7-list-input
            info="结束日期"
            type="datepicker"
            placeholder="结束日期"
            :value="limitoff_form_data.endTime"
            @calendar:change="limitoff_form_data.endTime = ($event[0].valueOf() / 1000)"
            :calendar-params="{
              closeOnSelect: true
            }"
            readonly
            required
            validate
          ></f7-list-input>
          <f7-list-input
            info="优惠券描述"
            type="text"
            placeholder="优惠券描述"
            required
            validate
            :value="limitoff_form_data.intro"
            @input="limitoff_form_data.intro = $event.target.value"
          >
          </f7-list-input>
        </f7-list>
      </f7-page>
    </f7-popup>
    <f7-fab position="right-bottom" slot="fixed" color="red">
      <f7-icon ios="f7:add" aurora="f7:add" md="material:add"></f7-icon>
      <f7-icon ios="f7:close" aurora="f7:close" md="material:close"></f7-icon>
      <f7-fab-buttons position="top">
        <f7-fab-button label="餐厅满减" @click="openCreateLimitoff()">+</f7-fab-button>
        <f7-fab-button label="优惠券" @click="openCreateCoupon()">+</f7-fab-button>
      </f7-fab-buttons>
    </f7-fab>
  </f7-page>
</template>

<script>
import invoke from '../libs/invoke'
export default {
  data() {
    return {
      coupon_rules: [],
      coupon_detail: null,
      limitoff_detail: null,
      couponPopupOpened: false,
      limitoffPopupOpened: false,
      coupon_form_data: null,
      limitoff_form_data: null
    }
  },
  async mounted() {
    await this.fetchData()
  },
  methods: {
    async fetchData(e, done) {
      const coupon_rules = await invoke('coupon.getCouponRules')
      this.coupon_rules = coupon_rules
      done && done()
    },
    async fetchCouponDetail(coupon_rule_id) {
      this.coupon_detail = null
      this.coupon_detail = await invoke('coupon.getCouponDetail', { coupon_rule_id })
    },
    async fetchLimitoffDetail(coupon_rule_id) {
      this.limitoff_detail = null
      this.limitoff_detail = await invoke('coupon.getLimitoffDetail', { coupon_rule_id })
    },
    openCreateCoupon() {
      this.coupon_form_data = {}
      this.couponPopupOpened = true
    },
    openCreateLimitoff() {
      this.limitoff_form_data = {}
      this.limitoffPopupOpened = true
    },
    async generateCoupon() {
      await invoke('coupon.createCoupon', this.coupon_form_data)
      await this.fetchData()
      this.couponPopupOpened = false
      this.coupon_form_data = null
    },
    async generateLimitoff() {
      await invoke('coupon.createLimitoff', this.limitoff_form_data)
      await this.fetchData()
      this.limitoffPopupOpened = false
      this.limitoff_form_data = null
    },
    async reinit() {
      await this.fetchData()
    }
  },
}
</script>