const config = require('../config/' + process.env.NODE_ENV + '.js')

module.exports = (method, params = {}) => {
  const token = localStorage.getItem('token')
  return fetch(`${config.server}/apiv1/merchant`, {
    method: 'POST',
    body: JSON.stringify({
      method,
      params,
      token
    }),
    headers: { 'Content-Type': 'application/json' }
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error) {
        global.$eco.error(res.error)
        throw new Error(res.error.message)
      } else {
        return res.result
      }
    })
}